<template>
    <input inputmode="numeric" id="billing-cc-number" class="cc-number" type="text" ref="input" v-bind:value="value" v-on:input="updateValue($event.target.value)" required>
</template>
<script>
import creditcardutils from 'creditcardutils';

export default {
    name: 'credit-card-input',
    props: ['value'],
    methods: {
        updateValue: function(value) {
            var formattedValue = creditcardutils.formatCardNumber(value);
            this.$refs.input.value = formattedValue;
            this.$emit('input', formattedValue);
        }
    }
}
</script>
<style lang="scss">
#app-checkout input.cc-number {
  background-image: url('./img/card_sprite.png'), url('./img/card_sprite.png');
  background-position: -2px -4px,224px -106px;
  background-size: 62px 282px,34px 166px;
  background-repeat: no-repeat;
  padding-left: 58px;
  width: 262px;
}

#app-checkout input.visa {
  background-position: -2px -145px,224px -106px;
}

#app-checkout input.mastercard {
  background-position: -2px -98px,224px -106px;
}

#app-checkout input.discover {
  background-position: -2px -51px,224px -106px;
}

#app-checkout input.visa.valid {
  background-position: -2px -145px,224px -133px;
}

#app-checkout input.mastercard.valid {
  background-position: -2px -98px,224px -133px;
}

#app-checkout input.discover.valid {
  background-position: -2px -51px,224px -133px;
}
</style>