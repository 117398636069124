<template>
    <div id="cart-panel" v-bind:class="{ open: isOpen }">
        <header>
            <button id="close-sidecart" @click="closePanelButton">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
            <h1>My basket</h1>
            <p></p>
        </header>
        <div id="app-cart">
            <p><span id="donate-phone-help">Need help?<br/>Call toll-free<br/>{{ donate_phone }}</span>Tributes can be added in the next step after clicking "Complete giving" below. MCC accepts Visa, Mastercard, Discover (U.S. only) and PayPal. PayPal cannot be used with monthly gifts at this time.</p>
            <transition name="fade" mode="out-in">
                <cart :cart="cart" :currency="currency" :editable="true" :processingForm="processingForm" v-if="cart.length > 0" key="cart"></cart>
                <div class="empty-cart" v-else key="empty">
                    <p>Your basket is empty.</p>
                    <p><a :href="baseUrl">Browse categories</a> or <a :href="project_list_url">view all available giving projects.</a></p>
                </div>
            </transition>
        </div>
        <transition name="component-fade" mode="out-in">
            <div id="app-checkout" v-if="orderSetup">
                <checkout :orderSetup="orderSetup" :processingForm="processingForm"></checkout>
            </div>
        </transition>
    </div>
</template>

<script>
import Cart from './Cart.vue';
import Checkout from './Checkout.vue';
import { CartEventBus } from '../event-bus.js';
import { CartApi, getCartBaseUrl } from '../cart-api.js';
import debounce from 'lodash/debounce';

export default {
    name: 'CheckoutAppPanel',
    data() {
        return {
            cart: [],
            baseUrl: '/',
            currency: 'USD',
            suggestions: [],
            processingForm: false,
            orderSetup: null,
            isOpen: false
        }
    },
    watch: {
        isOpen: function(newOpen) {
            // Set the last known state of the panel
            // So, if someone hits the back button the panel will still be open.
            // No bueno - best available solution at the moment
            if (newOpen == true) {
                history.pushState('cart', '', '');
            } else {
                history.pushState('', '', '');
            }
        }
    },
    computed: {
        donate_phone: function () {
            return this.currency == 'USD' ? '1-888-563-4676' : '1-888-622-6337';
        },
        project_list_url: function () {
            return this.currency == 'USD' ? 'https://donate.mcc.org/projects' : 'https://donate.mcccanada.ca/projects';
        }
    },
    mounted() {
        CartEventBus.$on('removeItem', id => {
            var cart = this.cart;

            var removedItem = null;

            cart.forEach(function(item, index){
                if (item.cart_item_id === id) {
                    removedItem = cart.splice(index, 1);
                }
            });

            CartEventBus.$emit('itemRemoved', cart, removedItem[0]);

            CartApi.delete(`api/cart/item/${id}`)
            .then(response => {
                // Force the state to reload so bfcache works right
                CartEventBus.$emit('loadCart');
            })
            .catch(error => {
                // If the item does not exist, do nothing. Else add it back.
                if (error.response.status == 404) {
                } else {
                    cart.push(removedItem[0]);
                }
            });
        });

        // Analytics
        CartEventBus.$on('itemAdded', function(cart, item){
            // Mixpanel Tracking
            if (typeof mixpanel !== 'undefined') {
                mixpanel.track("Add Item", {
                    amount: item.amount,
                    nid: item.nid,
                    recurrance: item.recurrance,
                    title: item.title,
                    qty: item.qty
                });
            }

            // Google Analytics
            if (typeof ga !== 'undefined') {
                ga('ec:addProduct', {
                    'id': item.nid,
                    'name': item.title,
                    'brand': item.is_giving_registry ? item.sub_title : '',
                    'category': item.is_giving_registry ? 'Giving Registry' : 'Giving Project',
                    'variant': item.recurrance,
                    'price': item.amount,
                    'quantity': item.qty
                });
                ga('ec:setAction', 'add');
                ga('send', 'event', 'UX', 'click', 'add to basket');
            }

            // Facebook Pixel AddToCart
            if(typeof fbq !== 'undefined'){
                fbq('track', 'AddToCart', {
                    value: item.amount
                });
            }
        });

        CartEventBus.$on('itemRemoved', function(cart, item){
            // Google Analytics
            if (typeof ga !== 'undefined') {
                ga('ec:addProduct', {
                    'id': item.nid,
                    'name': item.title,
                    'brand': item.is_giving_registry ? item.sub_title : '',
                    'category': item.is_giving_registry ? 'Giving Registry' : 'Giving Project',
                    'variant': item.recurrance,
                    'price': item.amount,
                    'quantity': item.qty
                });
                ga('ec:setAction', 'remove');
                ga('send', 'event', 'UX', 'click', 'remove from basket');
            }

            // Mixpanel Tracking
            if (typeof mixpanel !== 'undefined') {
                mixpanel.track("Remove Item", {
                    amount: item.amount,
                    nid: item.nid,
                    recurrance: item.recurrance,
                    title: item.title,
                    qty: item.qty
                });
            }
        });

        CartEventBus.$on('addItem', item => {
            CartApi.post('api/cart/item', item)
            .then(response => {
                this.cart = response.data;
                CartEventBus.$emit('itemAdded', this.cart, this.cart.slice(-1)[0]);
                // Force the state to reload so bfcache works right
                CartEventBus.$emit('loadCart');
            })
            .catch(error => {
                if (error.response.status == 429) {
                    alert(error.response.data.error);
                } else {
                    alert('Sorry, the item could not be added to your basket. Try again in a few moments.');
                }
            });
        });

        CartEventBus.$on('loadCart', () => {
            CartApi.get('api/cart')
            .then(response => {
                this.cart = response.data;
                CartEventBus.$emit('cartLoaded', this.cart);

                if (this.orderSetup && this.cart.length == 0) {
                    this.orderSetup = null;
                } else if (this.orderSetup) {
                    CartEventBus.$emit('checkoutCart');
                }
            })
            .catch(error => {
            });
        });

        CartEventBus.$on('loadCurrency', () => {
            CartApi.get('api/cart/currency')
            .then(response => {
                this.currency = response.data.currency;
            })
            .catch(error => {
            });
        });

        // Persist the cart, best effort
        CartEventBus.$on('updateItem', debounce((itemId, data) => {
            this.updateCart();
        }, 200));

        CartEventBus.$on('updateCurrency', currency => {
            this.currency = currency;
            this.updateCart();
        });

        CartEventBus.$on('openPanel', () => {
            this.openPanel();
            jQuery("#cart-panel-overlay").fadeIn(700);
        });

        CartEventBus.$on('closePanel', () => {
            this.closePanel();
            jQuery("#cart-panel-overlay").fadeOut(700);
        });

        CartEventBus.$on('checkoutProcessing', (status) => {
            this.processingForm = status;
        });

        CartEventBus.$on('checkoutCart', () => {
            this.processingForm = true;

            CartApi.get('api/order')
            .then(response => {
                this.cart = response.data.cart;

                if (this.orderSetup) {
                    CartEventBus.$emit('checkoutUpdated', {
                        currency: response.data.order.currency,
                        recurring: response.data.order.recurring,
                        total: response.data.order.total,
                        cart: response.data.order.cart,
                        totalRecurring: response.data.order.total_recurring,
                        isGivingRegistryOrder: response.data.order.giving_registry_order
                    });
                } else {
                    this.orderSetup = response.data.order;
                }
            })
            .catch(error => {
                // Load any changes performed on the backend
                CartEventBus.$emit('loadCart');
                if (error.response.status == 400) {
                    this.$swal('Oops...', error.response.data.msg, 'error');
                } else {
                    alert('Sorry, there was a problem creating your donation. Try again in a few moments.');
                }
            })
            .then(() => {
                this.processingForm = false;
            });
        });

        if (history.state == 'cart') {
            CartEventBus.$emit('openPanel');
        }

        CartEventBus.$emit('loadCart');
        CartEventBus.$emit('loadCurrency');

        this.baseUrl = getCartBaseUrl();
    },
    methods: {
        closePanelButton: function() {
            CartEventBus.$emit('closePanel');
        },
        closePanel: function() {
            this.orderSetup = null;
            this.isOpen = false;
            // Release Background scroll
            const scrollY = document.body.style.top;
            document.body.style.top = '';
            document.body.style.left = '';
            document.body.style.right = '';
            document.body.style.position = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        },
        openPanel: function() {
            this.isOpen = true;
            // Prevent Background scroll
            document.body.style.left = '0';
            document.body.style.right = '0';
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        },
        updateCart: function() {
            this.processingForm = true;

            CartApi.post('api/cart/save', { cart: this.cart, currency: this.currency })
            .then(response => {
                if (this.orderSetup) {
                    CartEventBus.$emit('checkoutCart');
                }
            })
            .catch(error => {
            })
            .then(() => {
                this.processingForm = false;
            });
        }
    },
    components: {Cart, Checkout}
}
</script>

<style lang="scss">
#donate-phone-help {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  text-align: right;
  font-size: 14px;
  max-width: 160px;
  line-height: 18px;
}

div.empty-cart {
  box-sizing: border-box;
  font-size: 18pt;
  width: 100%;
  padding: 10pt;
  text-align: center;
  background-color: #DDDDDD;
}

// Animations
.component-fade-enter-active, .component-fade-leave-active {
  transition: all .6s ease;
}

.component-fade-enter, .component-fade-leave-to {
  transform: translateX(3%);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.button-primary[disabled],
.button-primary[disabled]:hover {
  color: white;
  -webkit-animation: button-color-swap 2.0s infinite ease-in-out;
  animation: button-color-swap 2.0s infinite ease-in-out;
}

@keyframes button-color-swap {
  0%, 100% {
    background: #F0F0F0;
    border-color: #F0F0F0;
  } 50% {
    background: #777777;
    border-color: #777777;
  }
}
</style>