<template>
    <input inputmode="numeric" id="billing-cc-expiration" type="text" ref="input" v-bind:value="value" v-on:input="updateValue($event.target.value)" required>
</template>
<script>
import creditcardutils from 'creditcardutils';

export default {
    name: 'credit-card-expiration-input',
    props: ['value'],
    methods: {
        updateValue: function(value) {
            var formattedValue = creditcardutils.formatCardExpiry(value);

            this.$refs.input.value = formattedValue;
            this.$emit('input', formattedValue);
        }
    }
}
</script>
<style lang="scss">
</style>