<template>
    <div>
        <form v-on:submit.prevent="submitBilling">
            <tribute :tribute="order.tribute" :currency="order.currency" v-if="order.isGivingRegistryOrder == false"></tribute>
            <h3>Billing information</h3>
            <fieldset>
                <div class="input-row">
                    <div class="input-group">
                        <label for="first_name">First name</label>
                        <input v-model="order.billing.billing_first_name" id="first_name" type="text" autocomplete="given-name" required>
                    </div>
                    <div class="input-group">
                        <label for="last_name">Middle name <span>(Optional)</span></label>
                        <input v-model="order.billing.billing_middle_name" id="middle_name" type="text" autocomplete="middle-name">
                    </div>
                    <div class="input-group">
                        <label for="last_name">Last name</label>
                        <input v-model="order.billing.billing_last_name" id="last_name" type="text" autocomplete="family-name" required>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="company">Company <span>(Optional)</span></label>
                        <input v-model="order.billing.billing_company" id="company" type="text" autocomplete="organization" size="25">
                        <p>(If you want your company to receive the tax receipt.)</p>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="line_1">Address</label>
                        <input v-model="order.billing.billing_street1" id="line_1" type="text" autocomplete="address-line1" size="25" required>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="city">City</label>
                        <input v-model="order.billing.billing_city" id="city" type="text" autocomplete="locality address-level2" size="25" required>
                    </div>
                    <div class="input-group">
                        <label for="zone">{{zonePlaceholder}}</label>
                        <select id="zone" v-model="order.billing.billing_zone.value" autocomplete="address-level1" required>
                            <option disabled value="">Please select one</option>
                            <option v-for="option in zoneOptions" v-bind:key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label for="postal_code">{{postalCodePlaceholder}}</label>
                        <input v-model="order.billing.billing_postal_code" id="postal_code" type="text" size="10" autocomplete="postal-code" required>
                        <transition name="fade">
                            <p class="validation-error plain" v-if="invalidPostalCode && order.billing.billing_postal_code != ''">{{ invalidPostalCode }}</p>
                        </transition>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="country">Country</label>
                        <select id="country" v-model="order.billing.billing_country.value" autocomplete="country" required>
                            <option v-for="option in countryOptions" v-bind:key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="primary_email">Email</label>
                        <input v-model="order.billing.primary_email" id="primary_email" type="email" autocomplete="email" size="25" required>
                    </div>
                    <div class="input-group">
                        <label style="display: inline-block;" for="phone_number">Phone number (Optional)</label>
                        <info-tooltip msg="So we can contact you if there is a problem with your donation."></info-tooltip>
                        <input v-model="order.billing.billing_phone" id="phone_number" type="tel" autocomplete="tel" size="25">
                    </div>
                </div>
            </fieldset>
            <fieldset id="comment-section">
                <div class="input-row">
                    <div class="input-group">
                        <label for="tax_receipt_delivery">Tax receipt delivery</label>
                        <select id="tax_receipt_delivery" v-model="order.billing.tax_receipt_delivery.value" required>
                            <option v-for="option in taxReceiptOptions" v-bind:key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                        <p v-if="order.recurring">You will receive a receipt for all subsequent monthly gifts at the end of the year.</p>
                    </div>
                    <div class="input-group">
                        <label>Comments and other instructions</label>
                        <textarea autocomplete="off" rows="3" v-model="order.billing.order_comment"></textarea>
                    </div>
                </div>
            </fieldset>
            <payment :order="order" :paypalSettings="orderSetup.paypal_settings"></payment>
            <div v-if="order.recurring">
                <div class="total-display">
                    <div class="total-label">Total monthly</div>
                    <div class="total-total">{{ formatCurrency(order.totalRecurring) }}</div>
                </div>
                <div class="total-display">
                    <div class="total-label">Total today</div>
                    <div class="total-total">{{ formatCurrency(order.total) }}</div>
                </div>
                <p>Your first donation will be withdrawn today. Subsequent monthly donations will be automatically withdrawn on the first or fifteenth day of each month. Please contact us if you have questions.</p>
            </div>
            <div v-else>
                <div class="total-display">
                    <div class="total-label">Total</div>
                    <div class="total-total">{{ formatCurrency(order.total) }}</div>
                </div>
                <p></p>
            </div>
            <button class="primary-button button-primary donate-complete-button" :disabled="processingForm"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>Donate<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></button>
        </form>
    </div>
</template>

<script>
import Tribute from './Tribute';
import Payment from './Payment';
import { CartApi } from '../cart-api.js';
import { CartEventBus } from '../event-bus.js';
import currency from '../currency.js';
import InfoTooltip from './InfoTooltip.vue';

export default {
    name: 'Checkout',
    props: ['orderSetup', 'processingForm'],
    data() {
        return {
            order: {
                isGivingRegistryOrder: this.orderSetup.giving_registry_order,
                recurring: this.orderSetup.recurring,
                total: this.orderSetup.total,
                totalRecurring: this.orderSetup.total_recurring,
                currency: this.orderSetup.currency,
                cart: this.orderSetup.cart,
                billing: this.orderSetup.billing,
                tribute: this.orderSetup.tribute,
                paymentMethod: this.orderSetup.payment_method,
                payment: this.orderSetup.payment_data,
                cardOptions: this.orderSetup.card_options,
                paymentData: {
                    cardType: '',
                    number: '',
                    exp: '',
                    cvv: '',
                    paypalAuthorized: false
                }
            },
            taxReceiptOptions: this.orderSetup.tax_receipt_choices,
            countryOptions: this.orderSetup.country_choices,
            paypalCurrencyTotal: {}
        }
    },
    computed: {
        zoneOptions: function() {
            var zones = this.orderSetup.zone_choices;
            var country = this.order.billing.billing_country;
            return zones[country.value] ? zones[country.value] : [''];
        },
        zonePlaceholder: function() {
            var country = this.order.billing.billing_country;

            if (country.value == 840) {
                return 'State';
            }

            if (country.value == 124) {
                return 'Province';
            }

            return 'Region';
        },
        postalCodePlaceholder: function() {
            var country = this.order.billing.billing_country;

            if (country.value == 840) {
                return 'ZIP code';
            }

            return 'Postal code';
        },
        invalidPostalCode: function() {
            // Format Canada with Space
            if (this.order.billing.billing_country.value == 124) {
                //Format the field
                var pc = this.order.billing.billing_postal_code.toUpperCase();

                if (pc.length >= 4) {
                    pc = pc.replace(/\W/g, '').replace(/(...)/, '$1 ');
                }

                this.order.billing.billing_postal_code = pc;
            }

            if (!this.order.billing.billing_country || !this.order.billing.billing_zone) {
                return false;
            }

            // United States
            if (this.order.billing.billing_country.value == 840 && /^\d{5}(-\d{4})?$/.test(this.order.billing.billing_postal_code)) {
                return false;
            } else if (this.order.billing.billing_country.value == 840) {
                return 'Format your ZIP code as 5 digits (eg. 12345) or ZIP code plus 4 (eg. 12345-6789).';
            }

            // Canada
            if (this.order.billing.billing_country.value == 124 && /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/.test(this.order.billing.billing_postal_code) && this.testPcMatchProvince()) {
                return false;
            } else if (this.order.billing.billing_country.value == 124) {
                return 'Format your postal code to be valid for the selected Province and include a space. (eg. R2C 0A1)';
            }

            // Otherwise it's fine
            return false;
        }
    },
    methods: {
        formatCurrency: function(value) {
            return currency(value);
        },
        testPcMatchProvince: function() {
            const provinceList = {
                69: ['A'], // NL
                71: ['B'], // NS
                75: ['C'], // PE
                70: ['E'], // NB
                76: ['G', 'H', 'J'], // QC
                74: ['K', 'L', 'M', 'N', 'P'], //ON
                68: ['R'], // MB
                77: ['S'], // SK
                66: ['T'], // AB
                67: ['V'], // BC
                73: ['X'], // NU
                72: ['X'], // NT
                78: ['Y'] // YT
            };

            if (provinceList[this.order.billing.billing_zone.value]) {
                var firstLetterUpper = this.order.billing.billing_postal_code.substring(0, 1).toUpperCase();
                return provinceList[this.order.billing.billing_zone.value].includes(firstLetterUpper) ? true : false;
            } else {
                return false;
            }
        },
        selectCountry: function(selectedOption, id) {
            this.order.billing.billing_zone = { value: "", label: "" };
        },
        submitBilling: function() {
            // Check inputs
            if (this.invalidPostalCode !== false) {
                this.$swal('Ooops...', 'Please provide a valid Postal/ZIP Code.', 'error');
                return;
            }

            if (this.order.billing.billing_zone == "") {
                this.$swal('Ooops...', `Please select a ${this.zonePlaceholder}.`, 'error');
                return;
            }

            if (this.order.paymentMethod == "paypal_mcc" && this.order.paymentData.paypalAuthorized === false) {
                this.$swal('Ooops...', `Please complete the PayPal authorization first.`, 'error');
                return;
            }

            if (this.order.paymentMethod == "paypal_mcc" && (this.paypalCurrencyTotal.total != this.order.total || this.paypalCurrencyTotal.currency != this.order.currency)) {
                this.$swal('Ooops...', `The checkout total or currency changed since you authorized PayPal. Please click the PayPal button to authorize the new amount/currency.`, 'error');
                return;
            }

            // Build the billing info request body
            var body = {
                billing: {
                    billing_first_name: this.order.billing.billing_first_name,
                    billing_middle_name: this.order.billing.billing_middle_name,
                    billing_last_name: this.order.billing.billing_last_name,
                    billing_phone: this.order.billing.billing_phone,
                    billing_company: this.order.billing.billing_company,
                    billing_street1: this.order.billing.billing_street1,
                    billing_street2: this.order.billing.billing_street2,
                    billing_city: this.order.billing.billing_city,
                    billing_postal_code: this.order.billing.billing_postal_code,
                    primary_email: this.order.billing.primary_email,
                    billing_zone: this.order.billing.billing_zone.value,
                    billing_country: this.order.billing.billing_country.value,
                    tax_receipt_delivery: this.order.billing.tax_receipt_delivery.value,
                    order_comment: this.order.billing.order_comment
                },
                tribute: this.order.tribute
            };

            let tributeMethod = body.tribute == null ? 'delete' : 'post';

            // Well this can't execute async
            // Because the order is mutated on the backend one save will overwrite the other

            CartEventBus.$emit('checkoutProcessing', true);

            CartApi.request({ method: tributeMethod, url: 'api/order/tribute', data: body.tribute })
            .then(resonse => {
                // Billing info request
                CartApi.put('api/order', body.billing)
                    .then(resonse => {
                        CartEventBus.$emit('billingComplete');
                    })
                    .catch(error => {
                        CartEventBus.$emit('checkoutProcessing', false);
                        // Handle the billing errors
                        if (error.response.status == 400) {
                            let errorList = '';

                            jQuery.each(error.response.data, function(key, arr) {
                                errorList += arr.join('<br>') + '\n';
                            });

                            this.$swal('Oops...', errorList, 'error');
                        } else {
                            this.$swal('Oops...', 'An unknown error happened. Please try again later and contact us if it continues.', 'error');
                        }
                    });
            })
            .catch(error => {
                CartEventBus.$emit('checkoutProcessing', false);

                if (error.response.status == 400) {
                    let errorList = '';

                    jQuery.each(error.response.data, function(key, arr) {
                        errorList += arr.join('<br>') + '\n';
                    });

                    this.$swal('Oops...', errorList, 'error');
                } else {
                    this.$swal('Oops...', 'An unknown error happened. Please try again later and contact us if it continues.', 'error');
                }
            });
        },
        addPayment: function() {
            let self = this;

            this.$recaptcha('addpayment').then(function(token) {
                // Step 1 - Get a token from NMI
                CartApi.get(`api/payment/nmi/url?token=${token}`)
                .then(response => {
                    var number = self.order.paymentData.number.replace(/\s/g, '');

                    // Remove previous elements
                    jQuery('#nmi_hidden').remove();

                    var iframe = document.createElement("iframe");
                    iframe.setAttribute("name", "nmi_hidden");
                    iframe.setAttribute("id", "nmi_hidden");
                    iframe.setAttribute("width", "0");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("border", "0");
                    iframe.setAttribute("src", "about:blank");
                    iframe.style.display = "none";

                    var hiddenForm = document.createElement("form");
                    hiddenForm.setAttribute("id", "nmi_hidden_form");
                    hiddenForm.setAttribute("target", "nmi_hidden");
                    hiddenForm.setAttribute("action", response.data.form_url);
                    hiddenForm.setAttribute("method", "post");
                    hiddenForm.setAttribute("enctype", "application/x-www-form-urlencoded");
                    hiddenForm.style.display = "none";

                    var field = document.createElement("input");
                    field.setAttribute("type", "hidden");
                    field.setAttribute("name", "billing-cc-number");
                    field.setAttribute("value", number);
                    hiddenForm.appendChild(field);

                    var field = document.createElement("input");
                    field.setAttribute("type", "hidden");
                    field.setAttribute("name", "billing-cc-exp");
                    field.setAttribute("value", `${self.order.paymentData.exp}`);
                    hiddenForm.appendChild(field);

                    var field = document.createElement("input");
                    field.setAttribute("type", "hidden");
                    field.setAttribute("name", "billing-cvv");
                    field.setAttribute("value", self.order.paymentData.cvv);
                    hiddenForm.appendChild(field);

                    document.body.appendChild(iframe);
                    iframe.contentDocument.body.appendChild(hiddenForm);

                    iframe.addEventListener('load', function(e) {
                        var token = iframe.contentDocument.body.innerText ? iframe.contentDocument.body.innerText : "";

                        if (token.length) {
                            CartApi.get(`api/payment/nmi?token-id=${token}`)
                            .then(response => {
                                CartEventBus.$emit('paymentComplete');

                                // Mixpanel Tracking
                                if (typeof mixpanel !== 'undefined') {
                                    mixpanel.track("Payment Added");
                                }

                                // Google Analytics Step 2
                                if (typeof ga !== 'undefined') {
                                    ga('ec:setAction', 'checkout_option', {'step': 2});
                                    ga('send', 'event', 'Checkout', 'Step', 'Add Payment', 2);
                                }

                                // Facebook Pixel AddPaymentInfo
                                if (typeof fbq !== 'undefined') {
                                    fbq('track', 'AddPaymentInfo');
                                }
                            })
                            .catch(error => {
                                CartEventBus.$emit('checkoutProcessing', false);
                                // Get the error send by NMI

                                // Handle the NMI error
                                if (error.response.status == 400) {
                                    self.$swal('Oops...', error.response.data.payment_error.result_message, 'error');
                                } else if (error.response.status == 429) {
                                    self.$swal('Oops...', error.response.data.error, 'error');
                                } else {
                                    self.$swal('Oops...', 'An unknown error happened. Please try again later and contact us if it continues.', 'error');
                                }

                                // Mixpanel Tracking
                                if (typeof mixpanel !== 'undefined') {
                                    mixpanel.track("NMI Payment Failed");
                                }
                            });
                        }
                    });

                    hiddenForm.submit();
                })
                .catch(error => {
                    CartEventBus.$emit('checkoutProcessing', false);

                    // Problem with sending order data to NMI and getting the NMI post URL
                    self.processingForm = false;

                    if (error.response.data.captchaError) {
                        self.$swal('', error.response.data.captchaError, 'error');

                        // Mixpanel Tracking
                        if (typeof mixpanel !== 'undefined') {
                            mixpanel.track("reCaptcha Failed");
                        }
                    } else {
                        self.$swal('', 'Sorry, there was an error sending your payment information to the processor. Please try again later and contact us if the problem continues.', 'error');
                    }
                });
            }).catch(error => {
                self.$swal('', "Couldn't create a CAPTCHA token. Try again later and contact us if this problem continues.", 'error');
            });
        },
        completeDonation: function() {
            CartApi.post('api/order/complete', { total: this.order.total })
            .then(response => {
                // Create a timeout to continue if analytics don't complete
                setTimeout(completeCheckout, 1750);
                let analyticsSent = false;

                function completeCheckout() {
                    if (!analyticsSent) {
                        analyticsSent = true;

                        // Remove the cart state
                        localStorage.removeItem('cartOpen');

                        // Redirect to the Thank You page
                        if (Drupal.settings.mcc_sidecart !== undefined) {
                            window.location = Drupal.settings.mcc_sidecart.baseUrl + 'thankyou';
                        } else {
                            window.location = '/thankyou';
                        }
                    }
                }

                // Facebook Pixel Purchase
                if (typeof fbq !== 'undefined') {
                    fbq('track', 'Purchase', {
                        value: this.order.total,
                        currency: this.order.currency
                    });
                }

                if (typeof ga !== 'undefined') {
                    ga('ec:setAction', 'checkout_option', {'step': 3});
                    ga('send', 'event', 'Checkout', 'Step', 'Payment Charged', 3);

                    // Set the currency
                    ga('set', 'currencyCode', this.order.currency);

                    // Add Products
                    this.order.cart.forEach(function(item, idx){
                        ga('ec:addProduct', {
                        'id': item.nid,
                        'name': item.title,
                        'brand': item.is_giving_registry ? item.sub_title : '',
                        'category': item.is_giving_registry ? 'Giving Registry' : 'Giving Project',
                        'variant': item.recurrance,
                        'price': item.amount,
                        'quantity': item.qty,
                        'position': idx + 1
                        });
                    });

                    //Add the purchase
                    ga('ec:setAction', 'purchase', {
                        'id': response.data.order_id,
                        'revenue': this.order.total
                    });

                    ga('send', 'event', 'Checkout', 'Complete');
                }

                // Mixpanel Tracking
                if (typeof mixpanel !== 'undefined') {
                    mixpanel.identify(this.order.billing.primary_email);
                    mixpanel.track("Checkout Complete", {
                        total: this.order.total,
                        recurring: this.order.totalRecurring
                    });
                    mixpanel.people.track_charge(this.order.total, {
                        recurring: this.order.totalRecurring
                    }, completeCheckout);
                }
            })
            .catch(error => {
                CartEventBus.$emit('checkoutProcessing', false);

                if (error.response.status == 400) {
                    let errorList = '';

                    if (error.response.data.payment_error) {
                        // Switch Steps
                        errorList = error.response.data.payment_error.result_message;
                    } else {
                        jQuery.each(error.response.data, function(key, arr) {
                            errorList += arr.join('<br>') + '\n';
                        });
                    }

                    this.$swal('Oops...', errorList, 'error');
                } else if (error.response.status == 401) {
                    // Something bad happened
                    window.location.reload(true);
                } else {
                    this.$swal('Oops...', 'An unknown error happened. Please contact us.', 'error');
                }
            });
        }
    },
    mounted() {
        CartEventBus.$on('billingComplete', () => {
            if (this.order.paymentMethod == "cc") {
                this.addPayment();
            } else {
                this.completeDonation();
            }
        });

        CartEventBus.$on('paymentComplete', () => {
            this.completeDonation();
        });

        CartEventBus.$on('paypalStart', amount => {
            this.order.paymentData.paypalAuthorized = false;
            this.paypalCurrencyTotal = amount;
        });

        CartEventBus.$on('paypalAuthorized', type => {
            this.order.paymentData.paypalAuthorized = true;
        });

        CartEventBus.$on('changeCardType', type => {
            this.order.paymentData.cardType = type;
        });

        CartEventBus.$on('removeTribute', () => {
            this.order.tribute = null;
        });

        CartEventBus.$on('addTribute', () => {
            this.order.tribute = {
                tribute_ack: '3',
                tribute_person_name: '',
                tribute_person_fname: '',
                tribute_person_lname: '',
                tribute_ack_country: '',
                tribute_ack_addr: '',
                tribute_ack_city: '',
                tribute_ack_prov: '',
                tribute_ack_pc: ''
            };
        });

        CartEventBus.$on('checkoutUpdated', (newData) => {
            this.order = { ...this.order, ...newData };
        });

        // Time how long this is in checkout
        // let checkoutTimer = setInterval(function() {
        //     window.secondsinCheckout = window.secondsinCheckout + 1;
        //     // Close the window if you leave it open for too long
        //     if (window.secondsinCheckout >= 780) {
        //         CartEventBus.$emit('closePanel');
        //         window.secondsinCheckout = 0;
        //         clearTimeout(checkoutTimer);

        //         // Mixpanel Tracking
        //         if (typeof mixpanel !== 'undefined') {
        //             mixpanel.track("Checkout Panel Timeout");
        //         }
        //     }
        // }, 1000);

        // Scroll to it
        jQuery('#cart-panel').animate({
            scrollTop: jQuery("#app-checkout").offset().top
        }, 700);
    },
    components: { Tribute, Payment, InfoTooltip }
}
</script>

<style lang="scss">
$mcc-teal: #0092AC;

#app-checkout {
    h3 {
        font-size: 20pt;
        margin-bottom: 0.5em;
    }

    // Forms
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;

        legend {
            font-size: 16pt;
            font-weight: bold;
        }

        label {
            line-height: 18.24px;
            font-size: 15.2px;
            margin-bottom: 4px;
            font-weight: bold;

            span {
                vertical-align: text-bottom;
                font-size: 13px;

                svg {
                    vertical-align: bottom;
                }
            }
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='tel'],
    select,
    textarea {
        box-sizing: border-box;
        height: 2.5em;
        color: #353535;
        font-size: 1rem;
        border-color: #b9b9b9;
        border-radius: 4px;
        padding: 5pt;
        width: 100%;

        &:focus,
        &:active {
            border-color: $mcc-teal;
            outline-color: $mcc-teal;
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='tel'],
    textarea {
        font-size: 1rem;
    }

    textarea {
        resize: none;
        height: 4.5em;
    }

    .input-row {
        display: flex;
        flex-basis: 0;
        flex-grow: 5;
        flex-shrink: 1;

        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }

        .input-group:not(:last-child) {
            margin-right: 0.75rem;
        }

        .input-group {
            width: 100%;

            p {
                font-size: 13.12px;
                line-height: 15.7px;
                margin-top: 5px !important;
                padding: 0 !important;
                color: #545454;

                &.validation-error {
                    font-size: 14px;
                    color: #f44242;
                    transition: all 0.7s ease-in-out;
                    overflow: hidden;

                    &.plain {
                        color: #565656;
                    }
                }
            }
        }

        .validation-error {
            font-size: 14px;
            color: #f44242;
            transition: all 0.7s ease-in-out;
            overflow: hidden;
        }
    }

    #comment-section div.input-group {
        display: block;
    }

    #comment-section textarea {
        width: 100%;
    }

    @media screen and (max-width: 719px) {
        .input-row {
            display: block;
        }

        .input-group:not(:last-child) {
            margin-bottom: 0.75rem;
        }
    }
}

.total-display {
    display: table;
    width: 100%;
    box-sizing: border-box;

    .total-label {
        display: table-cell;
    }

    .total-total {
        display: table-cell;
        text-align: right;
        font-size: 26px;
        font-weight: bold;
    }
}

button.donate-complete-button {
    width: 100%;
    font-size: 1.5em;

    svg {
        width: 1.5em;
        height: 1.5em;
        vertical-align: top;
    }

    svg:first-of-type {
        width: 1.25em;
        height: 1.25em;
        margin-right: 1.5em;
        vertical-align: text-top;
    }

    svg:last-of-type {
        margin-left: 1em;
    }
}
</style>
