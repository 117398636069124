import axios from 'axios';

export const getCartBaseUrl = function() {
    if (Drupal.settings.mcc_sidecart !== undefined) {
        return Drupal.settings.mcc_sidecart.baseUrl;
    } else {
        return '/';
    }
};

export const CartApi = axios.create({
    baseURL: getCartBaseUrl(),
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
})
