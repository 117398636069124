<template>
    <div>
        <div class="input-row">
            <div class="input-group">
                <label for="billing-cc-number">Card number</label>
                <credit-card-input v-model="paymentData.number" :class="[type, {valid: validCard}]"></credit-card-input>
                <transition name="fade">
                    <p class="validation-error" v-if="unsupportedCard">Sorry, we don't accept {{type}} at this time.</p>
                </transition>
            </div>
            <div class="input-group">
                <label for="billing-cc-expiration">Expiration (MM/YY)</label>
                <credit-card-expiration-input v-model="expString" :class="[type, {valid: validExpiration}]"></credit-card-expiration-input>
                <transition name="fade">
                    <p class="validation-error" v-if="!validExpiration && expString">Invalid expiration date.</p>
                </transition>
            </div>
            <div class="input-group">
                <label style="display: inline-block;" for="billing-cc-cvv">CVV</label>
                <info-tooltip :msg="cvvMessage"></info-tooltip>
                <input inputmode="numeric" id="billing-cc-cvv" v-model="paymentData.cvv" type="text" size="17" maxlength="3" pattern="[0-9]{3}" required>
            </div>
        </div>
        <div class="input-row">
            <div class="input-group">
                <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            </div>
        </div>
    </div>
</template>
<script>
import creditcardutils from 'creditcardutils';
import CreditCardInput from './CreditCardInput.vue';
import CreditCardExpirationInput from './CreditCardExpirationInput.vue';
import InfoTooltip from './InfoTooltip.vue';
import { CartEventBus } from '../event-bus';

export default {
    name: 'credit-card-form',
    props: ['cardOptions', 'paymentData'],
    data() {
        return {
            expString: '',
            cvvMessage: "To ensure your card is being used safely, we require that you enter your card verification number. This is also known as a card security code, CVV, CVV2 or CID. The verification number is a 3 digit number printed on your card. If you are using a Visa, Mastercard or Discover card, the 3 digit number is the rightmost group of numbers on the back of your card."
        }
    },
    computed: {
        type: function() {
            return creditcardutils.parseCardType(this.paymentData.number);
        },
        validCard: function() {
            return creditcardutils.validateCardNumber(this.paymentData.number);
        },
        unsupportedCard: function() {
            return this.cardOptions.includes(this.type) || this.type === null ? false : true;
        },
        validExpiration: function() {
            return creditcardutils.validateCardExpiry(creditcardutils.parseCardExpiry(this.paymentData.exp)) ? true : false;
        }
    },
    watch: {
        expString: function(newExp, oldExp) {
          this.paymentData.exp = newExp.replace(/\s/g, '');
        }
    },
    components: { CreditCardInput, CreditCardExpirationInput, InfoTooltip }
}
</script>
<style lang="scss">
</style>
