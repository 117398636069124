import Vue from 'vue'
import VueSweetAlert from 'vue-sweetalert'
import App from './components/CheckoutPanelApp.vue'
import { CartEventBus } from './event-bus.js'
import VueTippy, { TippyComponent } from 'vue-tippy'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { 
    siteKey: '6LeZImwUAAAAALTQrB-px1WMwEL9aqV-BAH6D2Cr' ,
    loaderOptions: {
        autoHideBadge: true
    }
});
Vue.use(VueSweetAlert)
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

window.secondsinCheckout = 0;
// App communication happens over the EventBus with legacy jQuery interceptors
window.CartEventBus = CartEventBus;

new Vue({
    el: '#cart-app-container-mount',
    render: h => h(App),
    mounted () {
        this.$swal.setDefaults({
            confirmButtonColor: '#0092ac'
        });
    }
})
