<template>
    <div>
        <p>Click the PayPal button to fill in your payment information.</p>
        <div id="paypal-button-container"></div>
    </div>
</template>
<script>
import { CartEventBus } from '../event-bus.js';
import { CartApi } from '../cart-api.js';

export default {
    name: 'paypal-form',
    props: ['order', 'paymentData', 'paypalSettings'],
    methods: {
        renderButton(){
            let self = this;
            let order = this.order;

            // Remove any old elements
            const container = document.getElementById("paypal-button-container");
            container.textContent = '';

            paypal.Button.render({
                env: this.paypalSettings.env, // sandbox | production

                // PayPal Client IDs - replace with your own
                // Create a PayPal app: https://developer.paypal.com/developer/applications/create
                client: {
                    sandbox: this.paypalSettings.client_id,
                    production: this.paypalSettings.client_id
                },
                style: {
                    label: 'pay',
                    size: 'responsive',
                    shape: 'rect',
                    color: 'gold'
                },
                // payment() is called when the button is clicked
                payment: function(data, actions) {
                    let amount = { total: order.total, currency: order.currency };
                    CartEventBus.$emit('paypalStart', amount);

                    // Make a call to the REST api to create the payment
                    return actions.payment.create({
                        transactions: [{
                            amount: amount
                        }]
                    });
                },
                // onAuthorize() is called when the buyer approves the payment
                onAuthorize: function(data, actions) {
                    var body = {
                        paymentID: data.paymentID,
                        payerID: data.payerID
                    };

                    CartApi.post('api/payment/paypal', body)
                        .then(response => {
                            CartEventBus.$emit('paypalAuthorized');

                            // Mixpanel Tracking
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track("Payment Added");
                            }

                            // Facebook Pixel AddPaymentInfo
                            if (typeof fbq !== 'undefined') {
                                fbq('track', 'AddPaymentInfo');
                            }

                            // Google Analytics Step 2
                            if (typeof ga !== 'undefined') {
                                ga('ec:setAction', 'checkout_option', {'step': 2});
                                ga('send', 'event', 'Checkout', 'Step', 'Add Payment', 2);
                            }
                        })
                        .catch(error => {
                            self.$swal('', 'Failed to add the PayPal authorization to your order. Contact us if this problem continues.', 'error');
                        });
                }
            }, '#paypal-button-container');
        }
    },
    mounted() {
        this.renderButton();
    },
    watch: {
        order: function(newOrder, oldOrder) {
            if (newOrder.currency !== oldOrder || newOrder.total != oldOrder.total) {
                this.renderButton();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
button {
    margin-top: 20px !important;
}
</style>