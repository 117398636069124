<template>
    <fieldset class="tribute">
        <h3>Make this gift in memory, in {{currency == 'CAD' ? 'honour' : 'honor'}} or in support of someone</h3>
        <transition name="fade">
            <div v-if="tribute !== null" class="animated">
                <span>Make this gift</span><br />
                <div class="tribute-selector">
                    <input type="radio" id="tribute_ack_in_memory" value="1" v-model="tribute.tribute_ack">
                    <label for="tribute_ack_in_memory">in memory</label>
                    <input type="radio" id="tribute_ack_in_honor" value="2" v-model="tribute.tribute_ack">
                    <label for="tribute_ack_in_honor">in {{currency == 'CAD' ? 'honour' : 'honor'}}</label>
                    <input type="radio" id="tribute_ack_in_support" value="3" v-model="tribute.tribute_ack">
                    <label for="tribute_ack_in_support">in support</label>
                </div>
                of <input id="tribute_person_name" type="text" placeholder="Person's name" maxlength="255" v-model="tribute.tribute_person_name" required>
                <transition name="fade">
                    <div class="tribute-address">
                        <div class="consent-group">
                            <label><input type="radio" value="yes" name="tribute_data_sharing" v-model="tribute.tribute_data_sharing" required> You may share my name and contact information with this person or group.</label>
                            <label><input type="radio" value="no" name="tribute_data_sharing" v-model="tribute.tribute_data_sharing"> You may not share my name and contact information with this person or group.</label>
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="tribute-address animated" v-if="tribute.tribute_ack == 1">
                        <p>If you'd like, you can have MCC send an acknowledgement to this address.</p>
                        <div class="input-row">
                            <div class="input-group">
                                <label for="tribute_person_fname">First name</label>
                                <input id="tribute_person_fname" type="text" placeholder="First name" maxlength="255" v-model="tribute.tribute_person_fname">
                            </div>
                            <div class="input-group">
                                <label for="tribute_person_lname">Last name</label>
                                <input id="tribute_person_lname" type="text" placeholder="Last name" maxlength="255" v-model="tribute.tribute_person_lname">
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="input-group">
                                <label for="tribute_ack_country">Country</label>
                                <input id="tribute_ack_country" type="text" placeholder="Country" maxlength="255" v-model="tribute.tribute_ack_country">
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="input-group">
                                <label for="tribute_ack_addr">Mailing address</label>
                                <input id="tribute_ack_addr" type="text" placeholder="Mailing address" maxlength="255" v-model="tribute.tribute_ack_addr">
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="input-group">
                                <label for="tribute_ack_city">City</label>
                                <input id="tribute_ack_city" type="text" placeholder="City" maxlength="255" v-model="tribute.tribute_ack_city">
                            </div>
                            <div class="input-group">
                                <label for="tribute_ack_prov">St/Pv</label>
                                <input id="tribute_ack_prov" type="text" placeholder="ST/PV" size="4" maxlength="255" v-model="tribute.tribute_ack_prov">
                            </div>
                            <div class="input-group">
                                <label for="tribute_ack_pc">PC/Zip</label>
                                <input id="tribute_ack_pc" type="text" placeholder="Zip/PC" size="10" maxlength="255" v-model="tribute.tribute_ack_pc">
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
        <button type="button" class="button-primary" @click="addTribute()" v-if="tribute === null">Add tribute</button>
        <button type="button" class="button-primary reverse" @click="removeTribute()" v-else>Remove tribute</button>
    </fieldset>
</template>
<script>
import { CartEventBus } from '../event-bus.js';

export default {
    props: ['tribute', 'currency'],
    data: function() {
        return {};
    },
    methods: {
        removeTribute: function() {
            CartEventBus.$emit('removeTribute');
        },
        addTribute: function() {
            CartEventBus.$emit('addTribute');
        }
    }
}
</script>
<style lang="scss" scoped>
.fade-enter-to,
.fade-leave {
    max-height: 370px;
}

.fade-enter,
.fade-leave-to {
    max-height: 0;
    opacity: 0;
}

.animated {
    transition: all 0.7s ease-in-out;
    overflow: hidden;
}

.consent-group {
    margin-left: 1px;
}

.tribute {
    padding: 19px;
    display: block;
    margin-bottom: 19px;

    h3 {
        font-size: 20pt;
        margin: 0;
    }

    .button-primary {
        display: block;
        width: auto;

        &.reverse {
            background-color: #D85266;
            border-color: #D85266;

            &:hover {
                background-color: white;
                color: #D85266;
            }
        }
    }
}

.tribute-address {
    margin-top: 20px;

    p {
        font-size: 16px;
        line-height: 19.2px;
    }
}

.tribute-selector {
    input[type="radio"] {
        display: none;
    }

    input[type="radio"]+label {
        display: inline-block;
        border: none;
        box-sizing: border-box;
        color: white;
        padding: 4px 12px;
        background-color: #D3D3D3;
        margin-right: 7px;
        margin-bottom: 7px;
        cursor: pointer;
    }

    input[type="radio"]:checked+label {
        background-color: #0092ac;
    }
}
</style>