<template>
    <div>
        <h3>Payment</h3>
        <fieldset>
            <div class="input-row">
                <div class="input-group">
                    <div class="payment-selector">
                        <input type="radio" id="payment-method-cc" value="cc" v-model="order.paymentMethod">
                        <label for="payment-method-cc">Credit card
                            <div>
                                <img v-if="order.cardOptions.includes('visa')" src="./img/cc_visa.jpg" alt="Visa">
                                <img v-if="order.cardOptions.includes('mastercard')" src="./img/cc_mc.jpg" alt="MasterCard">
                                <img v-if="order.cardOptions.includes('discover')" src="./img/cc_discover.jpg" alt="Discover">
                            </div>
                        </label>
                        <input v-if="!order.recurring" type="radio" id="payment-method-pp" value="paypal_mcc" v-model="order.paymentMethod">
                        <label v-if="!order.recurring" for="payment-method-pp">PayPal
                            <div>
                                <img src="https://www.paypal.com/en_US/i/logo/PayPal_mark_37x23.gif" alt="PayPal">
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="paymentblock">
                <transition name="fade2">
                    <credit-card-form v-if="order.paymentMethod == 'cc'" v-bind:paymentData="order.paymentData" v-bind:cardOptions="order.cardOptions" class="animated2"></credit-card-form>
                    <paypal-form v-if="order.paymentMethod == 'paypal_mcc'" v-bind:paypalSettings="paypalSettings" v-bind:paymentData="order.paymentData" v-bind:order="order" class="animated2"></paypal-form>
                </transition>
            </div>
        </fieldset>
    </div>
</template>
<script>
import CreditCardForm from './CreditCardForm.vue';
import PaypalForm from './PaypalForm.vue';

export default {
    name: 'Payment',
    props: ['order', 'paypalSettings'],
    methods: {},
    components: { CreditCardForm, PaypalForm }
}
</script>
<style lang="scss">
.fade2-enter-to,
.fade2-leave {
    max-height: 320px;
}

.fade2-enter,
.fade2-leave-to {
    max-height: 0;
    opacity: 0;
}

.animated2 {
    transition: all 0.7s ease-in-out;
    overflow: hidden;
}

.payment-selector {
    input[type="radio"] {
        display: none;
    }

    input[type="radio"]+label div {
        display: inline-block;
        position: relative;
        top: 5px;
        padding-left: 8px;
    }

    input[type="radio"]+label {
        display: inline-block;
        border: none;
        box-sizing: border-box;
        color: white;
        padding: 4px 12px;
        background-color: #D3D3D3;
        margin-right: 7px;
        margin-bottom: 7px;
        height: 2.5rem;
        cursor: pointer;
    }

    input[type="radio"]:checked+label {
        background-color: #0092ac;
    }
}
</style>